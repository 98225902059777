import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import MDNForm from "./MDNForm";
import Title from "../../common/Title";
import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import { __getMdnById } from "../../../api/mdn";
import { __getUserList } from "../../../api/user";

const MDNUpdate = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  const [mdn, setMdn] = useState({});
  const [users, setUsers] = useState([]);
  const { mdn_id } = useParams();
  const [userResponse, userCall, userApiLoading] = useAxios(__getUserList);
  const [response, call, apiLoading] = useAxios({
    ...__getMdnById(mdn_id),
  });

  useEffect(() => {
    call();
    user.admin && userCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setMdn(response.data);
      return;
    }

    if (response && !response.status) {
      nav("/mdn");
    }
  }, [response]);

  useEffect(() => {
    if (userResponse && userResponse.status) {
      setUsers(userResponse.data.data);
    }
  }, [userResponse]);

  if (apiLoading || userApiLoading || !mdn) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Title variant="h5" content="MDN Update" sx={{ mb: 3 }} gutterBottom />
      <MDNForm
        mode="update"
        initialValues={mdn}
        mdn_id={mdn.id}
        userData={users}
        handleRefreshClick={call}
      />
    </Box>
  );
};

export default MDNUpdate;
